'use client';

import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { BiGlobe } from 'react-icons/bi';
import { i18n } from '../../../i18n-config';

// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(
  ({ children, onClick }: { children: React.ReactNode; onClick: (e: any) => void }, ref: any) => (
    <Button
      variant=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-danger"
      style={{ outline: 'none', boxShadow: 'none' }}
    >
      <BiGlobe size={22} className="me-2" />
      {children}
    </Button>
  )
);

const localeMapper = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
  cn: '中文',
  de: 'German',
} as { [key: string]: string };

const I18nLocaleChanger = ({ ...attributes }) => {
  const params = useParams();
  const pathName = usePathname();

  const redirectedPathName = (locale: string) => {
    if (!pathName) return '/home/en';
    const segments = pathName.split('/');
    segments[2] = locale;
    return segments.join('/');
  };

  const lngFlagMapper = {
    en: 'https://cdn.pinkswan.ch/application/LUMBERJACK/2401070932597_24.png',
    es: 'https://cdn.pinkswan.ch/application/LUMBERJACK/24010709325978_24_2.png',
    fr: 'https://cdn.pinkswan.ch/application/LUMBERJACK/24010709325978_24_3.png',
    de: 'https://cdn.pinkswan.ch/application/LUMBERJACK/24010709325954_24_4.png',
  };

  return (
    <Dropdown {...attributes}>
      <Dropdown.Toggle as={CustomToggle}>{localeMapper[params?.lang as string]}</Dropdown.Toggle>
      <Dropdown.Menu>
        {i18n.locales.map((l) => (
          <Dropdown.Item key={l} as="span" className="d-block">
            <Link
              href={redirectedPathName(l)}
              className="text-decoration-none text-dark small d-flex align-items-center"
            >
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img src={lngFlagMapper[l]} className="me-2 img-fluid" alt={localeMapper[l]} />
              {localeMapper[l]}
            </Link>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default I18nLocaleChanger;
